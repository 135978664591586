import { gql } from '@apollo/client';
import {
  useRemoveUserHistoryMutation as useMutation,
  RemoveUserHistoryMutationOptions,
} from './__generated__/removeUserStoryHistory.generated';
import { UserHistoryListDocument } from '@/operations/queries/home/__generated__/useUserHistoryList.generated';

export const REMOVE_USER_HISTORY = gql`
  mutation RemoveUserHistory($userStoryHistoryId: Int!) {
    removeUserStoryHistory(data: { id: $userStoryHistoryId }) {
      ok
    }
  }
`;

export const useRemoveUserHistory = (
  options?: RemoveUserHistoryMutationOptions,
) => {
  const [mutate] = useMutation({
    ...options,
    update(cache, _, { variables }) {
      const currentStoryId = variables?.userStoryHistoryId;
      cache.evict({ id: `UserStoryHistory:${currentStoryId}` });
    },
    refetchQueries: [
      {
        query: UserHistoryListDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  return { mutate };
};
