import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { NavCloseBtn } from 'public/assets';
import Portal from '@components/commons/portal/Portal';

interface IBottomSheetContainer {
  title?: string;
  isCloseButtonClick?: boolean;
  children: JSX.Element[] | JSX.Element;
  isTitleHasUnderline?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
  marginTop?: number;
  contentHeight?: number;
  hasBottomSheetOverflowY?: boolean;
}

const BottomSheetContainer = ({
  title,
  children,
  isCloseButtonClick,
  isTitleHasUnderline = true,
  hasCloseButton,
  onClose,
  contentHeight,
  marginTop,
  hasBottomSheetOverflowY = true,
}: IBottomSheetContainer) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Portal>
      <div
        css={container({ marginTop, contentHeight, hasBottomSheetOverflowY })}
      >
        <div className={`bg fadeIn ${isCloseButtonClick && 'fadeOut'}`} />
        <div
          className={`bottom-sheet-wrapper up ${isCloseButtonClick && 'down'}`}
        >
          <div className="title-wrapper">
            {title && <div className="title">{title}</div>}
            {hasCloseButton && (
              <NavCloseBtn
                className="icon"
                onClick={onClose}
                width={24}
                height={24}
                fill={theme.colors.gray900}
              />
            )}
          </div>
          {isTitleHasUnderline && <div className="horizontal-bar" />}
          <div className="content">{children}</div>
        </div>
      </div>
    </Portal>
  );
};

const container = ({
  marginTop = 0,
  contentHeight = 230,
  hasBottomSheetOverflowY = true,
}: {
  marginTop?: number;
  contentHeight?: number;
  hasBottomSheetOverflowY?: boolean;
}) => css`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  z-index: 20;

  .bg {
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.gray900};
    opacity: 0.7;
  }

  .bottom-sheet-wrapper {
    position: fixed;
    overflow: hidden;
    width: 100%;
    max-width: 800px;
    bottom: 0;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 1.25em;
    overflow-y: ${hasBottomSheetOverflowY ? 'scroll' : 'hidden'};

    ${marginTop && `top: ${marginTop}px;`};

    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        ${theme.font.header.navigation}
        display: flex;
        width: 100%;
      }

      .icon {
        cursor: pointer;
      }
    }

    .horizontal-bar {
      height: 0.0625em;
      width: 100%;
      background-color: ${theme.colors.gray200};
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .up {
    animation: up 500ms ease-in-out;
  }

  .down {
    animation: down 500ms ease-in-out;
  }

  .fadeIn {
    animation: fadeIn 500ms ease-in-out;
  }

  .fadeOut {
    animation: fadeOut 500ms ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 0.7;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes up {
    0% {
      transform: translateY(${contentHeight}px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes down {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(${contentHeight}px);
    }
  }
`;

export default BottomSheetContainer;
