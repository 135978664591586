import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { GET_USER } from '@api/user';
import { GetUser } from '@customTypes/user';
import { getAdultCheckType } from '@common/utils';
import { STORAGE_KEY } from '@/common/values';

interface IStoryItemLink {
  children: JSX.Element[] | JSX.Element;
  storyId: number;
  isAdult?: boolean;
  setEventParams?: () => void;
}

// story item 클릭 시 성인작품 여부에 따라 로그인페이지, 또는 성인인증 페이지로 라우팅시켜주는 link component
const StoryItemLink = ({
  storyId,
  children,
  isAdult,
  setEventParams,
}: IStoryItemLink) => {
  const router = useRouter();
  const { data: userData } = useQuery<GetUser>(GET_USER, {
    fetchPolicy: 'network-only',
  });

  const type: 'pass' | 'auth' | 'adult' = getAdultCheckType({
    isAdultType: !!isAdult,
    userData: userData,
  });

  if (type === 'auth') {
    const handleStoryItemClick = () => {
      setEventParams && setEventParams();

      const redirectInfo = JSON.stringify({
        redirectUrl: `/story/${storyId}`,
        createdAt: new Date(),
      });
      localStorage.setItem(STORAGE_KEY.REDIRECT_INFO, redirectInfo);
    };

    return (
      <Link
        href={{
          pathname: '/auth/adult',
          query: {
            url: `/story/${storyId}`,
            ...router.query,
          },
        }}
      >
        <a onClick={handleStoryItemClick}>{children}</a>
      </Link>
    );
  } else if (type === 'adult') {
    return (
      <Link
        href={{
          pathname: '/adult',
          query: {
            url: `/story/${storyId}`,
            storyId,
            ...router.query,
            backUrl: '/',
          },
        }}
      >
        <a onClick={setEventParams}>{children}</a>
      </Link>
    );
  } else {
    return (
      <Link
        href={{ pathname: `/story/${storyId}`, query: { ...router.query } }}
      >
        <a onClick={setEventParams}>{children}</a>
      </Link>
    );
  }
};

export default StoryItemLink;
