import React, { useEffect, useState } from 'react';
import { checkDevice } from '@common/utils';

import BottomSheetContainer from '@components/commons/popup/container/BottomSheetContainer';
import NovelPopupContainer from '@components/commons/popup/container/NovelPopupContainer';
import { UserHistoryStoryMorePopupInner } from './UserHistoryStoryMorePopupInner';

interface IUserHistoryStoryMorePopup {
  isShow: boolean;
  storyName: string;
  onClickStoryDetailButton: () => void;
  onClickDeleteButton: () => void;
  onClose: () => void;
}

export const UserHistoryStoryMorePopup = ({
  storyName,
  isShow,
  onClickStoryDetailButton,
  onClickDeleteButton,
  onClose,
}: IUserHistoryStoryMorePopup) => {
  const [device, setDevice] = useState<DEVICE>();

  useEffect(() => {
    setDevice(checkDevice());
  }, []);

  if (!isShow) {
    return null;
  }

  if (device === 'PC') {
    return (
      <NovelPopupContainer title={storyName} hasCloseButton onClose={onClose}>
        <UserHistoryStoryMorePopupInner
          onClickStoryDetailButton={onClickStoryDetailButton}
          onClickDeleteButton={onClickDeleteButton}
        />
      </NovelPopupContainer>
    );
  }

  return (
    <BottomSheetContainer
      title={storyName}
      isTitleHasUnderline={false}
      hasBottomSheetOverflowY={false}
      onClose={onClose}
    >
      <UserHistoryStoryMorePopupInner
        onClickStoryDetailButton={onClickStoryDetailButton}
        onClickDeleteButton={onClickDeleteButton}
      />
    </BottomSheetContainer>
  );
};
