import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { STORAGE_KEY } from '@/common/values';

interface IBandLabel {
  text: string;
}

// 홈화면 작품 아이템 띠지형태의 라벨 컴포넌트
const BandLabel = ({ text }: IBandLabel) => {
  const [userLanguage, setUserLanguage] = useState('');

  useEffect(() => {
    const storageInfo = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
    const parsedStorageInfo = storageInfo && JSON.parse(storageInfo);

    setUserLanguage(parsedStorageInfo?.language);
  }, []);

  if (userLanguage === 'en_US') {
    return <></>;
  }

  return (
    <div css={wrapper}>
      <div className="band">{text}</div>
    </div>
  );
};

const wrapper = css`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-right: 0.25rem;
  .band {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.25rem;
    padding: 0px 0.25rem;
    background: ${theme.colors.white};
    color: ${theme.colors.primary500};
    border: 1.5px solid ${theme.colors.primary300};
    font-weight: 700;
    font-size: 0.688rem;
    letter-spacing: -0.5008px;
    border-radius: 0.25rem;
  }
`;

export default BandLabel;
