import React, { useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import useTranslation from 'next-translate/useTranslation';
import { NewStoryItemFragment } from '@operations/queries/home/__generated__/useNewStoryList.generated';
import { GenreStoryItemFragment } from '@/operations/queries/home/__generated__/useGetHomeTabGenreStoryList.generated';
import { SpOriginalDtoFragment } from '@operations/queries/home/__generated__/useSPOriginalStoryList.generated';
import { getStatusText, getLabelText } from '@common/utils';
import { HOME_TAB_TYPE } from '@customTypes/home';
import {
  setCurrentStoryLabelText,
  setPreviousSection,
} from '@slices/eventSlice';
import { Section } from '@customTypes/event';
import theme from '@styles/theme';
import { css } from '@emotion/react';
import { AdultLabelIcon, MoreLabelIcon } from 'public/assets';

import StoryItemTitle from '../title/StoryItemTitle';
import StoryItemLink from './link/StoryItemLink';
import BandLabel from '../label/BandLabel';
import SPImage from '@components/commons/image/SPImage';
import {
  StoryHorizontalCoverDtoFragment,
  StoryVerticalCoverDtoFragment,
} from '@/operations/fragments/__generated__/home.generated';
import { Story_Play_Type } from '@/baseType';
import { SearchSourceDtoFragment } from '@/operations/queries/home/__generated__/useSearchStory.generated';
import { UserHistoryStoryMorePopup } from '../../home/homeStory/userHistoryMorePopup/UserHistoryStoryMorePopup';
import { useRemoveUserHistory } from '@/operations/mutations/story/removeUserStoryHistory';
import { useRouter } from 'next/router';

interface ISmallStoryItem {
  index: number;
  data:
    | NewStoryItemFragment
    | SpOriginalDtoFragment
    | GenreStoryItemFragment
    | StoryVerticalCoverDtoFragment
    | SearchSourceDtoFragment
    | StoryHorizontalCoverDtoFragment;
  hasTitle?: boolean;
  section?: Section;
  showInteractiveBanner?: boolean;
  hasMoreIcon?: boolean;
  userHistoryId?: number;
}

const SmallStoryItem = ({
  data,
  section,
  hasTitle,
  index,
  showInteractiveBanner,
  hasMoreIcon,
  userHistoryId,
}: ISmallStoryItem) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);

  const { mutate: removeUserHistory } = useRemoveUserHistory();

  const setEventParams = () => {
    dispatch(
      setCurrentStoryLabelText(
        getLabelText({
          currentHomeTab: HOME_TAB_TYPE.Home,
          isFinished: data.isFinished,
          isOnHiatus: data.onHiatus,
          isUp: data.isUp,
        }),
      ),
    );
    section && dispatch(setPreviousSection(section));
  };

  const handleMoreIconClick = (e: any) => {
    e.preventDefault();
    setIsMorePopupOpen(true);
  };

  const handleDeleteButtonFromUserHistoryPopupClick = () => {
    userHistoryId &&
      removeUserHistory({
        variables: { userStoryHistoryId: userHistoryId },
      });
    setIsMorePopupOpen(false);
  };

  const handleStoryDetailButtonFromUserHistoryPopupClick = () => {
    setIsMorePopupOpen(false);
    router.push(`/story/${data.storyId}`);
  };

  return (
    <div
      css={wrapper({ hasMoreIcon })}
      data-name={data.name}
      data-index={index}
      data-id={data.storyId}
      data-label={getLabelText({
        currentHomeTab: HOME_TAB_TYPE.Home,
        isFinished: data.isFinished,
        isOnHiatus: data.onHiatus,
        isUp: data.isUp,
      })}
      data-status={getStatusText(data)}
      data-content-type={
        data.playType === Story_Play_Type.Interactive ? 'interactive' : 'novel'
      }
    >
      <StoryItemLink
        storyId={data.storyId}
        isAdult={data.isAdult}
        setEventParams={setEventParams}
      >
        <div className="thumbnail">
          <SPImage
            src={data?.mainImageFile?.link || ''}
            layout="fill"
            alt={data.name}
          />
          {hasMoreIcon && (
            <div className="more-icon">
              <MoreLabelIcon onClick={handleMoreIconClick} />
            </div>
          )}
          {data.isAdult && (
            <div className="adult-sign">
              <AdultLabelIcon width={16} height={16} />
            </div>
          )}
          {showInteractiveBanner && (
            <div className="labelContainer">
              <BandLabel text={t('common_screen_title_interactive')} />
            </div>
          )}
        </div>
        <>
          {hasTitle && (
            <StoryItemTitle
              title={data?.name}
              subTitle={`${t(data?.genreKey?.text?.key ?? '') || '-'} ・ ${
                data?.authorName
              }`}
              isShowUp={data.isUp}
            />
          )}
        </>
      </StoryItemLink>
      <UserHistoryStoryMorePopup
        storyName={data?.name}
        isShow={isMorePopupOpen}
        onClose={() => setIsMorePopupOpen(false)}
        onClickStoryDetailButton={
          handleStoryDetailButtonFromUserHistoryPopupClick
        }
        onClickDeleteButton={handleDeleteButtonFromUserHistoryPopupClick}
      />
    </div>
  );
};

const wrapper = ({ hasMoreIcon }: { hasMoreIcon?: boolean }) => css`
  display: flex;
  flex-direction: column;
  width: 7.5rem;
  text-align: left;
  cursor: pointer;

  .thumbnail {
    position: relative;
    width: 7.5rem;
    height: 11.375rem;

    img {
      border-radius: 0.22em;
      image-rendering: auto;
    }
  }

  .more-icon {
    position: absolute;
    top: 0.375rem;
    right: 0.375rem;
  }

  .adult-sign {
    position: absolute;
    top: ${hasMoreIcon ? '1.625rem' : '0.375rem'};
    right: 0.175rem;
  }

  .labelContainer {
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    width: 6.875rem;

    .thumbnail {
      width: 6.875rem;
      height: 10.375rem;
    }
  }
`;

export default SmallStoryItem;
