import React, { ReactNode, useEffect } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import Portal from '@components/commons/portal/Portal';
import { NavCloseBtn } from 'public/assets';
import { checkDevice } from '@/common/utils';

interface INovelPopupContainer {
  children: ReactNode;
  title: string;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

const NovelPopupContainer = ({
  title,
  children,
  hasCloseButton,
  onClose,
}: INovelPopupContainer) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Portal>
      <div css={backDrop} />
      <div css={container({ device: checkDevice() })}>
        <div className={hasCloseButton ? 'title-wrapper' : ''}>
          <h1 className={hasCloseButton ? 'title-small' : 'title'}>{title}</h1>
          {hasCloseButton && (
            <NavCloseBtn
              className="icon"
              onClick={onClose}
              width={24}
              height={24}
              fill={theme.colors.gray900}
            />
          )}
        </div>

        <div className="content">{children}</div>
      </div>
    </Portal>
  );
};

const backDrop = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  background: ${theme.colors.gray900};
  opacity: 0.6;
  overflow: hidden;
  z-index: 20;
`;

const container = ({ device }: { device: DEVICE }) => css`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 19.5rem;
  transform: translate(-50%, -50%);
  padding: 1.25rem 1.25rem;
  background: ${theme.colors.white};
  border-radius: 0.75rem;
  max-width: 23.4375rem;
  z-index: 29;

  .title-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 1rem;
    font: ${theme.font.header.headline1};
    color: ${theme.colors.gray900};
    text-align: center;
    margin-top: 0.625rem;
  }

  .title-small {
    color: ${theme.colors.gray900};
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: -0.3px;
  }

  .icon {
    cursor: pointer;
  }

  @media (max-width: ${theme.devices.mobile}) {
    width: 100%;
    max-width: 19.5rem;
  }
`;

export default NovelPopupContainer;
