import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { StoryHorizontalCoverDtoFragmentDoc } from '../../../fragments/__generated__/home.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserHistoryListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UserHistoryListQuery = { __typename?: 'Query', listUserStoryHistoryV3: { __typename?: 'ListableUserStoryHistory', totalCount: number, page: number, pageSize: number, list: Array<{ __typename?: 'UserStoryHistory', userStoryHistoryId: number, story: { __typename?: 'Story', storyId: number, name: string, oneLineDesc: string, authorName: string, numViews: number, numLikes: number, numComment: number, onHiatus: boolean, isFinished: boolean, isUp: boolean, isAdult: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, mainImageFile?: { __typename?: 'File', link: string } | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string, ko_KR: string } } | null } }> } };


export const UserHistoryListDocument = gql`
    query UserHistoryList($page: Int) {
  listUserStoryHistoryV3(data: {page: $page, pageSize: 6}) {
    totalCount
    page
    pageSize
    list {
      userStoryHistoryId
      story {
        ...StoryHorizontalCoverDto
      }
    }
  }
}
    ${StoryHorizontalCoverDtoFragmentDoc}`;

/**
 * __useUserHistoryListQuery__
 *
 * To run a query within a React component, call `useUserHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHistoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserHistoryListQuery(baseOptions?: Apollo.QueryHookOptions<UserHistoryListQuery, UserHistoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHistoryListQuery, UserHistoryListQueryVariables>(UserHistoryListDocument, options);
      }
export function useUserHistoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHistoryListQuery, UserHistoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHistoryListQuery, UserHistoryListQueryVariables>(UserHistoryListDocument, options);
        }
export type UserHistoryListQueryHookResult = ReturnType<typeof useUserHistoryListQuery>;
export type UserHistoryListLazyQueryHookResult = ReturnType<typeof useUserHistoryListLazyQuery>;
export type UserHistoryListQueryResult = Apollo.QueryResult<UserHistoryListQuery, UserHistoryListQueryVariables>;