import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { Button } from '@/components/commons';
import useTranslation from 'next-translate/useTranslation';

interface IUserHistoryStoryMorePopupInner {
  onClickStoryDetailButton: () => void;
  onClickDeleteButton: () => void;
}

export const UserHistoryStoryMorePopupInner = ({
  onClickStoryDetailButton,
  onClickDeleteButton,
}: IUserHistoryStoryMorePopupInner) => {
  const { t } = useTranslation();

  return (
    <div css={container}>
      <p className="info-text">{t('history_popup_description_delete_info')}</p>
      <div className="button-container">
        <Button
          customCSS={buttonStyle}
          isFill={false}
          title={t('common_screen_title_delete')}
          onClick={onClickDeleteButton}
        />
        <Button
          customCSS={secondButtonStyle}
          title={t('home_popup_button_story_info')}
          onClick={onClickStoryDetailButton}
        />
      </div>
    </div>
  );
};

const container = css`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;

  .info-text {
    color: ${theme.colors.gray600};
    ${theme.font.body.body2}
  }

  .button-container {
    padding-top: 1.25rem;
    display: flex;
    gap: 0.625rem;
  }
`;

const buttonStyle = css`
  width: 100%;
  height: 3em;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  background-color: ${theme.colors.gray300};
  color: ${theme.colors.gray800};
  border-radius: 0.375rem;
`;

const secondButtonStyle = css`
  width: 100%;
  height: 3em;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
`;
