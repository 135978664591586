import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveUserHistoryMutationVariables = Types.Exact<{
  userStoryHistoryId: Types.Scalars['Int'];
}>;


export type RemoveUserHistoryMutation = { __typename?: 'Mutation', removeUserStoryHistory: { __typename?: 'OkResponse', ok: number } };


export const RemoveUserHistoryDocument = gql`
    mutation RemoveUserHistory($userStoryHistoryId: Int!) {
  removeUserStoryHistory(data: {id: $userStoryHistoryId}) {
    ok
  }
}
    `;
export type RemoveUserHistoryMutationFn = Apollo.MutationFunction<RemoveUserHistoryMutation, RemoveUserHistoryMutationVariables>;

/**
 * __useRemoveUserHistoryMutation__
 *
 * To run a mutation, you first call `useRemoveUserHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserHistoryMutation, { data, loading, error }] = useRemoveUserHistoryMutation({
 *   variables: {
 *      userStoryHistoryId: // value for 'userStoryHistoryId'
 *   },
 * });
 */
export function useRemoveUserHistoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserHistoryMutation, RemoveUserHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserHistoryMutation, RemoveUserHistoryMutationVariables>(RemoveUserHistoryDocument, options);
      }
export type RemoveUserHistoryMutationHookResult = ReturnType<typeof useRemoveUserHistoryMutation>;
export type RemoveUserHistoryMutationResult = Apollo.MutationResult<RemoveUserHistoryMutation>;
export type RemoveUserHistoryMutationOptions = Apollo.BaseMutationOptions<RemoveUserHistoryMutation, RemoveUserHistoryMutationVariables>;