import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { AdultLabelIcon, HomeTabTitleUpIcon } from 'public/assets';

interface IStoryItemTitle {
  title?: string;
  subTitle?: string | null;
  author?: string | null;
  isShowUp?: boolean;
  isShowAdultIcon?: boolean;
}

const StoryItemTitle = ({
  title,
  subTitle,
  author,
  isShowUp,
  isShowAdultIcon = false,
}: IStoryItemTitle) => {
  return (
    <div css={container}>
      <div className="title-wrapper">
        {isShowUp && (
          <span>
            <HomeTabTitleUpIcon />
          </span>
        )}
        {isShowAdultIcon && (
          <AdultLabelIcon className="adult-icon" width={16} height={16} />
        )}
        <h2 className="title">{title}</h2>
      </div>
      <div>
        {author && <p className="author">{author}</p>}
        {subTitle && <p className="sub-title">{subTitle}</p>}
      </div>
    </div>
  );
};

const container = css`
  margin: 0.6875em 0.125em;

  .title-wrapper {
    display: flex;

    .adult-icon {
      flex-shrink: 0;
    }

    svg {
      margin-right: 0.25rem;
    }
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    height: 1.25rem;
    white-space: normal;
    color: ${theme.colors.gray900};
    ${theme.font.header.subhead2}
  }

  .author {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    white-space: normal;
    ${theme.font.body.body1};
    color: ${theme.colors.primary300};
  }

  .sub-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    height: 1rem;
    white-space: normal;
    ${theme.font.body.body1}
    color: ${theme.colors.gray600};
  }
`;

export default StoryItemTitle;
